import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Status from "../Status/Status";
import { Header, Footer, Spacer } from "../Header/Header";
import { Button, Buttons } from "../Button/Button";
import { itemData, mutateItem, parseItem, sortItems }
  from "./ItemFunctions.js"
import Form from "../Form/Form";
import Icon from "../Icon/Icon";
import * as Util from "../Utilities/Utilities";
import "./Page.css";
import Analytics from '../analytics';
import { InstallPrompt } from "../InstallPage/InstallPage";
import Updater from "../Updater/Updater";
import * as Actions from "../Actions/Actions";
import DownloadArchive from "./DownloadArchive";
import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isPlainObject';

import Amplify, { API, graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import awsmobile from '../amplify-config';
const configureAmplify = function() {
  awsmobile.API = { graphql_headers: async () => ({
    // Add token to headers for subscription auth
    token: localStorage.getItem("token")
  }) };
  Amplify.configure(awsmobile);
};
configureAmplify();

class Field extends Component {
  constructor(props) {
    super(props);
    // Specify intervals
    this.timeDiffInterval = null;
    this.timeIfInterval = null;
    // Set initial state
    this.state = {
      value: this.props.value,
      value_formatted: false,
      visible: true
    };
  }

  componentDidMount() {
    this.loadComponent();
  }

  componentDidUpdate(prevProps) {
    if(JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadComponent();
    }
  }

  loadComponent() {
    // Clear interval on load (clear existing intervals on re-load)
    clearInterval(this.timeDiffInterval);
    clearInterval(this.timeIfInterval);

    // Check if field should be visible
    this.evaluateVisibility();

    // Get value
    let value = this.getValue();
    // Update value in state
    this.setState({ value: value });

    // Format field
    const config = this.props.config;
    const format = ("format" in config)
      ? config.format : null;
    switch(format) {

      case "datetime":
        if(Util.isNumeric(value)) {
          // Check for options
          let options = ("format_options" in config)
            ? config.format_options
            : null;
          // Format timestamp
          this.formatTimestamp(value, options);
        }
        // Handle invalid value type
        else this.setState({ value_formatted: null });
        break;

      case "time":
        if(Util.isNumeric(value)) {
          // Convert to time
          this.formatTimestamp(value);
        }
        // Handle invalid value type
        else this.setState({ value_formatted: null });
        break;

      case "time_clock":
        if(Util.isNumeric(value)) {
          // Update clock now
          this.setState({ value_formatted: Util.formatClock(this.getValue()) })
          // Update clock every second
          this.timeDiffInterval = setInterval(() => {
            this.setState({ value_formatted: Util.formatClock(this.getValue()) })
          }, 1000);
        }
        // Handle invalid value type
        else this.setState({ value_formatted: null });
        break;

      case "time_diff":
        if(Util.isNumeric(value)) {
          // Update time now
          this.time_diff(value);
          // Update time_diff every second
          this.timeDiffInterval = setInterval(() => { this.time_diff() }, 1000);
        }
        // Handle invalid value type
        else this.setState({ value_formatted: null });
        break;

      case "time_since":
        if(Util.isNumeric(value)) {
          // Update time now
          this.time_diff(value, false);
          // Update time_since every second
          this.timeDiffInterval = setInterval(() => { this.time_diff(null, false) }, 1000);
        }
        // Handle invalid value type
        else this.setState({ value_formatted: null });
        break;

      case "time_until":
        if(Util.isNumeric(value)) {
          // Update time now
          this.time_diff(value, true);
          // Update time_until every second
          this.timeDiffInterval = setInterval(() => { this.time_diff(null, true) }, 1000);
        }
        // Handle invalid value type
        else this.setState({ value_formatted: null });
        break;

      default:
        // Log error
        if(format !== null) console.log("Invalid format: "+format);
        this.setState({ value_formatted: false });
    }

    // Update component every second if time-based condition
    if("if" in config
    && /\$time\./.test(JSON.stringify(config.if))
    && !this.timeDiffInterval) {
      // Set interval to check `if` every second
      this.timeIfInterval = setInterval(this.evaluateVisibility, 1000);
    }
  }

  getValue() {
    // Update value if manually set
    const config = this.props.config;
    let value = ("value" in config)
      ? config.value
      : this.state.value;

    // Replace any variables in specified value
    let data = {
      item: this.props.item,
      items: this.props.items,
      page: this.props.page
    }
    value = Util.insertVariables(value, data);

    // Apply algebraic logic
    if(this.props.config.algebra === true) {
      // Check if value is an algebraic function
      if(!Util.isAlgebraic(value)) value = null;
      // Evaluate function
      else value = Util.evaluate(value);
    }

    // Return value
    return value;
  }

  componentWillUnmount() {
    // Clear interval on unmount
    clearInterval(this.timeDiffInterval);
    clearInterval(this.timeIfInterval);
  }

  evaluateVisibility() {
    let config = ("config" in this.props) ? this.props.config: {};

    // Prepare data for inserting variables
    let ivdata = {
      token: this.props.token,
      groups: this.props.groups,
      user: this.props.user,
      item: this.props.item,
      page: this.props.page
    };

    // Check `if` in config
    let visible = true;
    if("if" in config) visible = Util.evalIf(config.if, ivdata);

    // Update state
    if(visible !== this.state.visible) this.setState({ visible: visible });
  }

  replace(value, replace) {
    let findVal, newVal
    if(typeof replace === "string") {
      // If `replace` is a string, the string is the `findVal`
      findVal = replace;
      newVal = "";
    } else {
      // If `replace` is an array, array contains `findVal` and `newVal`
      findVal = replace[0];
      newVal = (replace.length > 1) ? replace[1] : "";
    }

    // Convert findVal to RegEx if applicable
    let regexParse = (typeof findVal === "string")
      ? findVal.match(/^\/(.*)\/(\w+)?$/)
      : null;
    if(regexParse !== null) {
      findVal = (regexParse.length === 2)
        ? new RegExp(regexParse[1])
        : new RegExp(regexParse[1], regexParse[2]);
    }

    // Insert variables
    let ivdata = {
      item: this.props.item,
      page: this.props.page
    };
    newVal = Util.insertVariables(newVal, ivdata);

    // Replace `null` values
    if(value === null && findVal === null) return newVal;
    // Apply replace function and return new value
    return (value) ? value.toString().replace(findVal, newVal) : "";
  }

  formatTimestamp(value, options) {
    // Create new javascript date object
    let timestamp = new Date(value * 1000);

    // Default options
    if(!options) options = {
      hour: "numeric",
      minute: "numeric"
    };

    // Convert timestamp to pretty string with options
    let pretty = timestamp.toLocaleString('en-US', options);

    // Update in state
    this.setState({ value_formatted: pretty });
  }

  time_diff(value, tick_down) {
    // Get value from arguments or state
    value = (typeof value !== "undefined" && value !== null)
      ? value : this.state.value;

    // Get seconds since timestamp
    const now = Math.floor(new Date() / 1000);
    let since = (tick_down !== false)
      ? value - now
      : now - value;

    // Format seconds as clock
    let value_formatted = Util.formatClock(since);

    // Check if visibility has changed
    this.evaluateVisibility();

    // Process formatted value
    let new_value_formatted = (!Util.isNumeric(value)) ? value :  // Show raw value if not a number
      (since < 0 && typeof tick_down === "boolean")  // Hide if less than zero and `time_since` or `time_until`
        ? "" : value_formatted;
    // Check for changes and update state
    if(new_value_formatted !== this.state.value_formatted) {
      this.setState({ value_formatted: new_value_formatted });
    }
  }

  render() {
    // Check if visible
    if(this.state.visible === false) return "";

    // Get field value
    let value = (this.state.value_formatted !== false)
      ? this.state.value_formatted
      : this.state.value;

    // Replace value
    if("replace" in this.props.config && this.props.config.replace !== null) {
      const replace = this.props.config.replace;
      if(typeof replace === "string") {
        // Shorthand replace
        value = this.replace(value, replace);
      } else {
        // Loop through array of replaces
        for(let i=0; i<replace.length; i++) {
          value = this.replace(value, replace[i]);
        }
      }
    }

    // Use placeholder for empty value
    if(value === "" || value === null) value = <i>&nbsp;</i>

    // Field class
    const className = ("class" in this.props.config
    && this.props.config.class !== null)
      ? "field "+this.props.config.class
      : "field";

    // Return component
    return (
      <div
        style={
          Util.camelCase(("style" in this.props.config)
            ? this.props.config.style : {})
        }
        className={className}>
        <span
          style={
            Util.camelCase(("inner_style" in this.props.config)
              ? this.props.config.inner_style : {})
          }>
          {("prepend" in this.props.config) ? this.props.config.prepend : ''}
          {value}
          {("append" in this.props.config) ? this.props.config.append : ''}
        </span>
      </div>
    );
  }
}

class Item extends Component {
  constructor(props) {
    super(props);
    // Define intervals
    this.conditionsInterval = null;
    // Create state
    this.state = {
      config: {},
      loading: false,
      visible: true
    };
  }

  componentDidMount() {
    this.setState(this.evaluateConfig());

    // Process config every second
    this.conditionsInterval = setInterval(() => {
      let new_state = this.evaluateConfig(true);
      if(JSON.stringify(new_state.config) !== JSON.stringify(this.state.config)
      || new_state.visible !== this.state.visible) {
        this.setState(new_state);
      }
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if(JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.setState(this.evaluateConfig());
    }
  }

  componentWillUnmount() {
    // Remove intervals
    clearInterval(this.conditionsInterval);
  }

  evaluateConfig(invokeActions) {
    let config = this.props.config;

    // Prepare data for inserting variables
    let ivdata = {
      token: this.props.token,
      groups: this.props.groups,
      user: this.props.user,
      item: this.props.item,
      items: this.props.items,
      page: this.props.page,
      openForm: this.props.openForm.bind(this),
      redirect: this.props.redirect.bind(this),
      updateItem: this.props.updateItem.bind(this)
    };

    // Check for `if` in config
    let visible = true;
    if("if" in config) visible = Util.evalIf(config.if, ivdata);

    // Check for `conditions` in config
    config = Util.applyItemsConditions(config, ivdata);

    // Invoke item's actions
    if(invokeActions && "actions" in config) {
      Object.keys(config.actions).forEach((key) => {
        Actions.doAction(config.actions[key], ivdata);
      });
    }

    // Return status update
    return {
      config: config,
      visible: visible
    };
  }

  toggleLoading(loading) {
    loading = (typeof loading === "boolean") ? loading : !this.state.loading;
    this.setState({ loading: loading });
  }

  toggleMenu(e) {
    const menu_item_id = (this.props.menu_item_id === this.props.item.id)
      // Hide menu if this item's menu is open
      ? null
      // Show this item's menu if no menu is open or another item's menu is open
      : this.props.item.id;
    this.props.showMenu(menu_item_id);
  }

  render() {
    // Check if visible
    if(this.state.visible === false) return "";
    // Get config
    const config = this.state.config;
    // Item style
    const style = ("style" in config) ? config.style : {};
    // Item data
    let data = this.props.item;
    // Items
    let items = this.props.items;
    // Token
    let token = this.props.token;
    // Groups
    let groups = this.props.groups
    // User
    let user = this.props.user;

    // Item fields
    let fields = [];
    let page = this.props.page;
    if("fields" in config && config.fields !== null && Object.keys(config.fields).length > 0) {
      // Show specified fields
      Object.keys(config.fields)
        .sort((a,b) => {
          // Sort by specified "order"
          if("order" in config.fields[a]) {
            if("order" in config.fields[b]) {
              return config.fields[a].order - config.fields[b].order;
            } else return -1;
          } else if("order" in config.fields[b]) return 1;

          // Sort by field IDs
          return a.localeCompare(b);
        })
        .forEach(function(key) {
        fields.push(
          <Field
            key={key}
            name={key}
            page={page}
            item={data}
            items={items}
            token={token}
            config={config.fields[key]}
            groups={groups}
            user={user}
            value={itemData(data, key)}/>
        );
      });
    }

    // Create item buttons
    let buttons = [];
    let menu = "";
    let config_buttons = {};
    if("buttons" in config && config.buttons !== null) config_buttons = config.buttons;
    // Get all item buttons in config and sort
    let button_keys = Object.keys(config_buttons).sort((a, b) => {
      let sort = 0;
      // Get conditions
      let orderA = ("order" in config.buttons[a])
        ? config.buttons[a].order : null;
      let orderB = ("order" in config.buttons[b])
        ? config.buttons[b].order : null;

      // Sort by order
      if(orderA !== null && orderB !== null) sort = orderA - orderB;
      else if(orderA === null && orderB === null) sort = 0;
        else if(orderA !== null && orderB === null) sort = -1;
          else if(orderA === null && orderB !== null) sort = 1;

      // Sort by ID, if needed
      if(sort === 0) sort = a.localeCompare(b);

      // Return result
      return sort;
    });

    // Loop through buttons
    for(let i=0; i<button_keys.length; i++) {
      let button = config.buttons[button_keys[i]];
      // Add button
      buttons.push(
        <Button
          key={i}
          id={button_keys[i]}
          item={this.props.item}
          items={this.props.items}
          groups={this.props.groups}
          user={this.props.user}
          page={this.props.page}
          token={this.props.token}
          toggleLoading={this.toggleLoading.bind(this)}
          openForm={this.props.openForm.bind(this)}
          redirect={this.props.redirect.bind(this)}
          updateItem={this.props.updateItem.bind(this)}
          button={button}/>
      );
    }

    // Item class
    let className = "card";

    // Create buttons
    if(buttons.length > 0) {
      // Indicate item has a menu
      className += " hasMenu";

      // Add buttons to container
      menu = (
        <div className="menu">
          {buttons}
        </div>
      );
    }

    // Indicate loading
    let loading = "";
    if(this.state.loading) {
      loading = (
        <div className="loading">
          <div className="loading_wrapper">
            <Icon name="spinner"/>
          </div>
        </div>
      );
    }

    // Class name from config
    className += ("class" in config && config.class !== null)
      ? " "+config.class
      : "";

    // Show/hide menu
    if(this.props.menu_item_id === this.props.item.id) {
      className += " showMenu";
    } else {
      menu = "";
    }

    // Return result
    return (
      <div
        style={Util.camelCase(style)}
        onClick={this.toggleMenu.bind(this)}
        className={className}>
        {fields}
        {(loading) ? loading : menu}
      </div>
    );
  }
}

class Columns extends Component {
  render() {
    // Sort columns
    let config = this.props.config;
    let values = config.values;
    let cols = Object.keys(values);
    cols.sort((a, b) => {
      if("order" in values[a]) {
        // If both have an order
        if("order" in values[b]) return values[a].order - values[b].order;  // Compare orders
        // If a has an order but b doesn't
        else return -1;  // Sort a before b
      }
      // If b has an order but a doesn't
      else if("order" in values[b]) return 1;  // Sort b before a
      // If neither a nor b have order
      else return a.localeCompare(b);
    });

    // Column class
    let className = "Column";
    // Class name from config
    className += ("class" in config
    && config.class !== null)
      ? " "+config.class
      : "";

    // Loop through items, group into columns (by field specified in config)
    let colGroups = {};
    let noCol = [];
    let items = this.props.items;
    for(let i=0; i<items.length; i++) {
      let item = items[i];
      let itemData = item.props.item;
      // Get field's value for this item
      let fieldVal = (config.field in itemData)
        ? itemData[config.field]
        : ("data" in itemData && config.field in itemData.data)
          ? itemData.data[config.field]
          : null;
      // Check if item's field value is one of the column values
      if(fieldVal !== null && cols.indexOf(fieldVal) >= 0) {
        // Check if group exists for this column yet
        if(!(fieldVal in colGroups)) {
          colGroups[fieldVal] = {
            items: [],
            faux_items_count: 0
          };
        }
        // Add item to column
        colGroups[fieldVal].items.push(item);
        // Count faux items
        if(itemData.faux === true) colGroups[fieldVal].faux_items_count++;
      }
      // If item is not in a column, add to list of items not in columns
      else noCol.push(item);
    }

    // Check if all columns have items
    for(let i=0; i<cols.length; i++) {
      let col = cols[i];
      // Check if column has any items
      if(!(col in colGroups)) {
        // Do not create columns if some columns are not full
        // Unless `persistent: true` in config
        if("persistent" in config && config.persistent !== true) {
          return this.props.items;
        }
      }
      // Check if column has any non-faux items
      else if(colGroups[col].items.length === colGroups[col].faux_items_count) {
        // Do not create columns if some columns are not full (ignoring faux items)
        // Unless `ignore_faux_items: false` in config
        if(!("ignore_faux_items" in config) || config.ignore_faux_items === false) {
          return this.props.items;
        }
      }
    }

    // Create columns
    let columnComponents = [];
    for(let i=0; i<cols.length; i++) {
      // Column-specific classes
      className += ("class" in config.values[cols[i]]
      && config.values[cols[i]].class !== null)
        ? " "+config.values[cols[i]].class
        : "";
      // Create column
      columnComponents.push(
        <div
          key={cols[i]}
          className={className}>
          {colGroups[cols[i]].items}
        </div>
      );
    }
    return [columnComponents, noCol];
  }
}

class Bucket extends Component {
  constructor(props) {
    super(props);
    // Create state
    this.state = {
      more: false
    };
  }

  render() {
    // Define classes
    const className = "Bucket card " +
      (("class" in this.props.buckets) ? this.props.buckets.class + " " : "") +
      ((this.state.more) ? "viewingMore" : "");

    // Define bucket name
    const name = (this.props.name) ? this.props.name : this.props.value;

    // Headers
    let headers = [];
    if("headers" in this.props.buckets
    && this.props.buckets.headers !== null) {
      Object.keys(this.props.buckets.headers)
        .sort((a,b) => {
          // Sort by specified "order"
          if("order" in this.props.buckets.headers[a]) {
            if("order" in this.props.buckets.headers[b]) {
              return this.props.buckets.headers[a].order - this.props.buckets.headers[b].order;
            } else return -1;
          } else if("order" in this.props.buckets.headers[b]) return 1;
          // Fallback to string comparison for consistent results
          return a.localeCompare(b);
        })
        .forEach((key) => {
          let h = this.props.buckets.headers[key];
          headers.push(
            <span
              key={key}
              className={"field " + (("class" in h) ? h.class : "")}>
              {key}
            </span>
          );
        });
    }

    // Handle items
    let items = (typeof this.props.items === "undefined") ? []
      : this.props.items;

    return (
      <div className={className}>
        <h1
          className={(this.props.required) ? "required" : ""}
          title={(this.props.required) ? "This zone is required by default" : ""}>
          {name}
        </h1>
        {(headers.length === 0) ? ""
          : <div className="headers">{headers}</div>}
        <div className="items">
          {(this.state.more) ? items : items.slice(0, 5)}
          {(items.length > 0) ? "" :
            <div className="emptyBucket">
              No Items
            </div>}
        </div>
        {(items.length < 5) ? "" :
          <div
            className="more"
            onClick={() => { this.setState({ more: !this.state.more }) }}>
            <div className="moreButton">
              View {(this.state.more) ? "Less" : "More"}
            </div>
          </div>}
      </div>
    );
  }
}

class Buckets extends Component {
  render() {
    // Get buckets field
    const field = this.props.buckets.field;

    // Create buckets from values
    let buckets = ("values" in this.props.buckets
      && this.props.buckets.values !== null)
        ? cloneDeep(this.props.buckets.values)
        : {};

    // Handle `$zones()`
    if(buckets === "$zones()" && "zones" in this.props.page) {
      buckets = {};
      for(let i=0; i<this.props.page.zones.length; i++) {
        let zone = this.props.page.zones[i];
        if(field in zone) {
          // Add zone to buckets
          buckets[zone[field]] = { order: i };

          // Add name, if given
          if("name" in zone) buckets[zone[field]].name = zone.name;

          // Add `required`
          buckets[zone[field]].required = ("required" in zone)
            ? zone.required : true;
        }
      }
    }

    // Add items to buckets
    for(let i=0; i<this.props.items.length; i++) {
      let itemComponent = this.props.items[i];

      // Get bucket field value for this item
      let value = (field in itemComponent.props.item)
        ? itemComponent.props.item[field]
        : null;

      // Create bucket if it does not exist
      if(!(value in buckets)) buckets[value] = {};
      if(!("items" in buckets[value])) buckets[value].items = [];

      // Add item to bucket
      buckets[value].items.push(itemComponent);
    }

    // Add bucket components
    let res = [];
    Object.keys(buckets)
      .sort((a,b) => {
        // Sort by specified "order"
        if("order" in buckets[a]) {
          if("order" in buckets[b]) {
            return buckets[a].order - buckets[b].order;
          } else return -1;
        } else if("order" in buckets[b]) return 1;
        // Fallback to no sort if order is not defined
        return 0;
      })
      .forEach((key) => res.push(
        <Bucket
          key={key}
          value={key}
          name={("name" in buckets[key]) ? buckets[key].name : null}
          required={("required" in buckets[key]) ? buckets[key].required : true}
          buckets={this.props.buckets}
          items={buckets[key].items}/>
      ));

    // Return bucket objects
    return res;
  }
}

class Lists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: {},
      menu_item_id: null
    };
  }

  componentDidMount() {
    this.loadComponent();
  }

  componentDidUpdate(prevProps) {
    // Load component when props change
    if(JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.loadComponent();
    }
    // Handle archive show/hide
    if(prevProps.showArchive !== this.props.showArchive) {
      // Close item menu when archive is visible
      if(this.props.showArchive) this.setState({ menu_item_id: null });
    }
  }

  loadComponent() {
    // Subscribe to list changes
    this.props.subscribe();
  }

  showMenu(item_id) {
    this.setState({ menu_item_id: item_id });
  }

  render() {
    // Hide when showing archived items
    if(this.props.showArchive) return "";

    // Indicate loading if no "lists"
    if(this.props.page.preloaded) return <Status status="loading"/>;

    // Indicate loading if waiting for items to be loaded
    if(this.props.last_updated === 0) return <Status status="loading"/>;

    // Show message if no items to show
    if(this.props.items.length === 0) return (
      <div
        key="empty"
        className="empty">
        <Icon name="planet"/>
        <h1>No items to show</h1>
        <h2>Add something to see data here</h2>
      </div>
    );

    // Create page object
    const page = {
      id: this.props.page_id,
      name: this.props.page.name,
      zones: this.props.page.zones,
      config: this.props.page.config,
      list_ids: this.props.page.list_ids
    };

    // Get page config
    const page_config = ("config" in this.props.page
      && this.props.page.config !== null) ? this.props.page.config : {};
    // Get config object for items
    let item_config = ("items" in page_config)
      ? page_config.items : {};
    // Create Item for each element in items array
    let itemComponents = [];
    let items = this.props.items;
    // Add faux items and re-sort
    if(this.props.faux_items.length > 0) {
      items = sortItems(items.concat(this.props.faux_items), page_config);
    }
    for(let i=0; i<items.length; i++) {
      itemComponents.push(
        <Item
          key={Date.now()+"."+i}
          config={item_config}
          items={cloneDeep(this.props.items)}
          groups={this.props.groups}
          user={this.props.user}
          page={(isObject(page)) ? cloneDeep(page) : {}}
          token={this.props.token}
          openForm={this.props.openForm.bind(this)}
          redirect={this.props.redirect.bind(this)}
          updateItem={this.props.updateItem.bind(this)}
          menu_item_id={this.state.menu_item_id}
          showMenu={this.showMenu.bind(this)}
          item={items[i]}/>
      );
    }

    // Group items into columns
    if("columns" in this.props.page.config.items
    && "field" in this.props.page.config.items.columns
    && this.props.page.config.items.columns.field !== ""
    && this.props.page.config.items.columns.field !== null) {
      return <Columns
        config={this.props.page.config.items.columns}
        items={itemComponents}/>
    }

    // Group items into buckets
    if("buckets" in this.props.page.config.items
    && "field" in this.props.page.config.items.buckets
    && this.props.page.config.items.buckets.field !== ""
    && this.props.page.config.items.buckets.field !== null) {
      return <Buckets
        buckets={this.props.page.config.items.buckets}
        page={this.props.page}
        items={itemComponents}/>
    }

    // Return items
    return itemComponents;
  }
}

class ListsArchived extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_term: "",
      items_archived: [],
      loading: true,
      menu_item_id: null
    };
    // Search timeout
    this.searchTimeout = null;
  }

  componentDidUpdate(prevProps) {
    // Handle archive show/hide
    if(prevProps.showArchive !== this.props.showArchive) {
      // Get archived items when archive becomes visible
      if(this.props.showArchive) this.getArchivedItems();
      // Close item menu and clear items when archive is hidden
      else this.setState({
        search_term: "",
        items_archived: [],
        menu_item_id: null
      });
    }
  }

  getArchivedItems(bookmark) {
    // Clear list of items unless getting more items
    if(!bookmark) {
      this.setState({ items_archived: [] });
    }

    // Indicate loading
    this.setState({ loading: true });

    // Create query
    let query = `query
      GetPage($token: String!, $page_id: ID!, $filter: String, $filter_fields: [ String ], $archived_since: Int, $limit: Int, $bookmark: String) {
        getPage(token: $token, page_id: $page_id) {
          lists {
            items_archived (filter: $filter, filter_fields: $filter_fields, archived_since: $archived_since, limit: $limit, bookmark: $bookmark) {
              id
              list_id
              created_at
              updated_at
              archived_at
              bookmark
              data
            }
          }
        }
      }`;

    // Define params
    let params = {
      token: this.props.token,
      page_id: this.props.page_id,
      limit: 10,
      bookmark: (typeof bookmark === "string") ? bookmark : null
    };
    // Add search term
    if(this.state.search_term !== "") {
      params.filter = this.state.search_term.toLowerCase();
      params.filter_fields = this.props.page.config.items_archived.search.fields;
      // Limit time, if specified
      if("limit_time" in this.props.page.config.items_archived.search
      && Number.isInteger(this.props.page.config.items_archived.search.limit_time)) {
        // Limit time to the past ___ seconds
        params.archived_since = Math.floor(Date.now() / 1000) -
          this.props.page.config.items_archived.search.limit_time;
      }
    }

    // Query API
    API.graphql(graphqlOperation(query, params))
      // Success
      .then((res) => {
        // Get items from lists
        let lists = res.data.getPage.lists;
        let items_archived = [];
        for(let i=0; i<lists.length; i++) {
          // Loop through items in list
          for(let j=0; j<lists[i].items_archived.length; j++) {
            items_archived.push(parseItem(lists[i].items_archived[j]))
          }
        }
        // Save items to state and stop loading
        this.setState({
          items_archived: this.state.items_archived
            .concat(items_archived),
          loading: false
        });
      })
      // Error
      .catch((err) => {
        // Log error
        console.error(err);
        // Stop loading
        this.setState({ loading: false });
      });
  }

  handleChange(event) {
    // Update value in state & clear archived items
    this.setState({ search_term: event.target.value });

    // Clear previous timeout (prevent duplicate searches)
    clearTimeout(this.searchTimeout);
    // Search (use a timeout to prevent searches during typing)
    this.searchTimeout = setTimeout(this.getArchivedItems.bind(this), 200);
  }

  handleSubmit(event) {
    // Prevent uncontrolled submit
    event.preventDefault();
  }

  showMenu(item_id) {
    this.setState({ menu_item_id: item_id });
  }

  render() {
    // Hide if not showing archived items
    if(!this.props.showArchive) return "";

    // Store components in an array
    let components = [];

    // Show searchbar
    if("search" in this.props.page.config.items_archived) {
      components.push(
        <form
          key="searchbar"
          onSubmit={this.handleSubmit.bind(this)}
          className="searchbar">
          <input
            type="text"
            placeholder="Search"
            value={this.state.search_term}
            onKeyUp={this.handleChange.bind(this)}
            onChange={this.handleChange.bind(this)}/>
        </form>
      );
    }

    // Indicate loading
    if(this.state.loading && this.state.items_archived.length === 0) {
      components.push(<Status key="status" status="loading"/> );
    }

    // Show message if no items to show
    if(!this.state.loading && this.state.items_archived.length === 0) {
      let message = (this.state.search_term === "")
        ? "No items have been archived yet"
        : "No items match your search";
      components.push(
        <div
          key="empty"
          className="empty">
          <Icon name="planet"/>
          <h1>{message}</h1>
          <h2>Items appear here after they have been archived</h2>
        </div>
      );
    }

    // Show archived items
    const items_archived = this.state.items_archived;
    for(let i=0; i<items_archived.length; i++) {
      components.push(
        <Item
          key={Date.now()+"."+i}
          config={this.props.page.config.items_archived}
          openForm={this.props.openForm.bind(this)}
          redirect={this.props.redirect.bind(this)}
          updateItem={() => {}}
          menu_item_id={this.state.menu_item_id}
          showMenu={this.showMenu.bind(this)}
          item={items_archived[i]}/>
      );
    }

    // Download archived items button
    let downloadButton = (
      <DownloadArchive
        page={this.props.page}
        items={this.state.items_archived}/>
    );

    // Show button if more items can be loaded from archive
    const last_item = (items_archived.length > 0)
      ? items_archived[items_archived.length - 1]
      : {};
    let moreButton = (last_item.bookmark !== null) ? (
      <div className={"loadMore"+((this.state.loading)?" loading":"")}
        onClick={() => {
          if(this.state.loading) return; // Prevent double-clicks
          this.getArchivedItems(last_item.bookmark);  // Get more items
        }}>
        View More
        <Icon name="spinner"/>
      </div>
    ) : "";

    // Buttons
    if(items_archived.length > 0 && (downloadButton !== "" || moreButton !== "")) {
      components.push (
        <div key="buttons" className="archiveButtons">
          {moreButton}
          {(downloadButton !== "" && moreButton !== "") ? <br/> : ""}
          {downloadButton}
        </div>
      );
    }

    // Return components
    return components;
  }
}

class ArchiveToggle extends Component {
  render() {
    // Hide archive toggle if archived items config is not specified
    if(!("items_archived" in this.props.config)) return "";

    return (
      <p className="toggle">
        <span
          className={(!this.props.showArchive) ? "active" : ""}
          onClick={() => this.props.toggleArchive(false)}>
          Active
        </span>
        <span
          className={(this.props.showArchive) ? "active" : ""}
          onClick={() => this.props.toggleArchive(true)}>
          Archive
        </span>
      </p>
    );
  }
}

class PageContent extends Component {
  constructor(props) {
    super(props);
    // Variables
    this.subscriptions = {};
    this.cueInterval = null;
    this.pageHiddenTimeout = null;
    this.sleepTimeout = null;
    // Bind functions
    this.reconnect = this.reconnect.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    // Set initial state
    this.state = {
      redirect: false,
      form_data: {},
      status: null,
      last_updated: 0,
      show_archive: false,
      faux_items: [],
      items: []
    };
  }

  componentDidMount() {
    // Listen for page visibility change
    document.addEventListener("visibilitychange", this.handleVisibilityChange);

    // Check for items with a cue
    this.cueInterval = setInterval(this.cueCheck.bind(this), 1000);

    // Add faux items
    this.addFauxItems();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.new_token !== this.props.new_token
    && this.props.new_token !== null) {
      this.updateToken(this.props.new_token);
    }
  }

  componentWillUnmount() {
    // Remove event listeners
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);

    // Remove intervals
    clearInterval(this.cueInterval);
  }

  handleVisibilityChange() {
    // Page is visible
    if(document.hidden === false) {
      // Reconnect if not subscribed to any lists
      if(Object.keys(this.subscriptions).length === 0) this.reconnect();
      // Restart cue check
      clearInterval(this.cueInterval);  // Prevent duplicate intervals
      this.cueInterval = setInterval(this.cueCheck.bind(this), 1000);
      // Clear hidden page timeout
      clearTimeout(this.pageHiddenTimeout);
    }

    // Page is hidden
    if(document.hidden === true) {
      // Disconnect from server after some time away from page
      this.pageHiddenTimeout = setTimeout(this.disconnected.bind(this), 3000);
    }
  }

  disconnected(error) {
    // Unsubscribe while page is invisible or error occured
    this.unsubscribe();
    this.setState({ status: (error)
      ? "disconnected-error" : "disconnected" });
    // Clear intervals
    clearInterval(this.cueInterval);
    // Clear timeouts
    clearTimeout(this.pageHiddenTimeout);
    clearTimeout(this.sleepTimeout);
  }

  cueCheck() {
    // Get current time
    const now = Math.floor(new Date() / 1000);

    // Search items for scheduled events
    for(let i=0; i<this.state.items.length; i++) {
      let item = this.state.items[i];
      if("cue" in item && item.cue > 0 && item.cue <= now) {
        // Set props
        let props = {
          token: this.props.token,
          item: item,
          items: this.state.items,
          user: this.props.user,
          groups: this.props.groups,
          page: this.props.page,
          openForm: this.openForm.bind(this),
          redirect: this.redirect.bind(this),
          updateItem: this.updateItem.bind(this),
          updateToken: this.props.updateToken.bind(this)
        };

        // Get cue config
        let itemsConfig = ("config" in this.props.page
        && "items" in this.props.page.config)
          ? this.props.page.config.items
          : {};

        // Apply conditions to items
        itemsConfig = Util.applyItemsConditions(itemsConfig, props);

        // Invoke cue action
        Actions.doAction(itemsConfig.cue, props);
      }
    }
  }

  openForm(params) {
    // Additional form data
    params.user = this.props.user;
    params.users = this.props.users;
    params.items = this.state.items;
    params.groups = this.props.groups;
    params.updateItem = this.updateItem.bind(this);

    // Update form data in state
    this.setState({ form_data: params });
  }

  closeForm() {
    this.setState({ form_data: {} });
  }

  evaluateIf() {
    // Get config
    const config = this.props.page.config;

    // Ignore `config.if` while loading page
    if(!this.props.loaded) return true;

    // Prepare data
    let ivdata = {
      token: this.props.token,
      groups: this.props.groups,
      user: this.props.user,
      page: this.props.page
    };

    // Check for `if` in config
    return Util.evalIf(config.if, ivdata);
  }

  reconnect() {
    // Indicate loading
    this.setState({ status: "loading" });
    // Re-subscribe to lists
    this.subscribe();
  }

  subscribe() {
    // Unsubscribe after a long period (prevent never-ending connections)
    clearTimeout(this.sleepTimeout);
    this.sleepTimeout = setTimeout(this.disconnected.bind(this), 57600000);

    // Check if children should be shown instead of items
    const show_children = ("items" in this.props.page.config
      && this.props.page.config.items.children);

    // Check if websockets are supported
    this.websocketCheck((supported) => {

      // Subscribe to item mutations for each list
      let lists = ("lists" in this.props.page) ? this.props.page.lists : [];
      for(let i=0; i<lists.length; i++) {
        let list_id = lists[i].id;

        // Skip lists already subscribed to
        if(list_id in this.subscriptions) {
          this.setState({ status: null });
          continue;
        }

        // Next, subscribe and save subscription to object
        this.subscriptions[list_id] = this.listSubscribe(list_id, supported, show_children);
        this.setState({ status: null });
      }
    });
  }

  listSubscribe(list_id, websockets_supported, show_children) {
    let subscription;

    // Subscribe to GraphQL mutations if websockets are supported
    if(websockets_supported) {
      // Start list_id subscription
      let list_subscription = API.graphql(
        graphqlOperation(`subscription
          MutatedItem($list_id: ID) {
            mutatedItem(list_id: $list_id) {
              id
              list_id
              created_at
              updated_at
              archived_at
              bookmark
              cue
              data
              parent {
                id
                list_id
                created_at
                updated_at
                archived_at
                bookmark
                cue
                data
              }
              parent_id
              parent_list_id
              parent_page_id
            }
          }`, { list_id: list_id })
      ).subscribe({
        error: (err) => this.disconnected(true),
        next: (eventData) => {
          // When a mutation occurs, update that item (handle children elsewhere)
          if(!show_children) this.updateItem(parseItem(eventData.value.data.mutatedItem));

          // Update parent in children
          if(show_children) this.updateParentItem(parseItem(eventData.value.data.mutatedItem));
        }
      });

      // Start parent_list_id subscription
      let parent_list_subscription;
      if(show_children) parent_list_subscription = API.graphql(
        graphqlOperation(`subscription
          MutatedItem($parent_list_id: ID) {
            mutatedItem(parent_list_id: $parent_list_id) {
              id
              list_id
              created_at
              updated_at
              archived_at
              bookmark
              cue
              data
              parent {
                id
                list_id
                created_at
                updated_at
                archived_at
                bookmark
                cue
                data
              }
              parent_id
              parent_list_id
              parent_page_id
            }
          }`, { parent_list_id: list_id })
      ).subscribe({
        error: (err) => this.disconnected(true),
        next: (eventData) => {
          // When a mutation occurs on a child item, update that child item
          this.updateItem(parseItem(eventData.value.data.mutatedItem));
        }
      });

      // Create object to unsubscribe from subscriptions
      subscription = { unsubscribe: () => {
        // Unsubscribe from list
        list_subscription.unsubscribe();
        // Unsubscribe from list's parent
        if(parent_list_subscription) parent_list_subscription.unsubscribe();
      } };
    }

    // Poll API if websockets are not supported
    else {
      // Log lack of WebSocket support
      console.warn("WebSockets are not available on this network");

      // Poll API for updated items periodically (no websockets)
      const sub = setInterval(() => this.getListItems(list_id), 2500);

      // Create object to ubsubscribe from subscription
      subscription = { unsubscribe: () => { clearInterval(sub) } };
    }

    // Update list after subscribing
    this.getListItems(list_id);

    // Return subscription
    return subscription;
  }

  unsubscribe() {
    Object.keys(this.subscriptions).forEach((key) => {
      // Unsubscribe to list
      this.subscriptions[key].unsubscribe();
      // Remove from object of subscriptions
      delete this.subscriptions[key];
    });
  }

  websocketCheck(callback) {
    let supported;
    let checked = false;
    // Define callback handler
    const invokeCallback = () => {
      // Invoke callback only once
      if(checked) return;
      checked = true;
      // Invoke callback
      callback(supported);
      // Indicate in analytics if websockets are supported
      Analytics.properties({ websockets: supported });
    };
    // Create connection timeout
    setTimeout(() => {
      invokeCallback();
      if(typeof connection.close === "function") connection.close();
    }, 5000);
    // Check websocket connection
    const connection = new WebSocket('wss://websocket.lotsuite.com');
    connection.onopen = () => {
      supported = true;
      connection.close();
    };
    connection.onerror = (err) => { supported = false; };
    connection.onclose = () => { invokeCallback(); };
  }

  addFauxItems() {
    // Return if no faux items in config
    if(!("items" in this.props.page.config)
    || !("faux_items" in this.props.page.config.items)) return;

    // Return if faux items already added
    if(this.state.faux_items.length > 0) return;

    // Add each faux item
    let faux_items = [];
    let faux_items_config = this.props.page.config.items.faux_items;
    let keys = Object.keys(faux_items_config);
    for(let i=0; i<keys.length; i++) {
      // Add `faux` to each faux item
      let faux_item = faux_items_config[keys[i]];
      faux_item.faux = true;
      // Add item to array
      faux_items.push(faux_item);
    }

    // Add faux items to state
    if(faux_items.length > 0) this.setState({ faux_items: faux_items });
  }

  getListItems(list_id, callback, last_updated = this.state.last_updated) {
    const now = Math.floor(Date.now() / 1000);
    // Check if children should be queried
    let show_children = ("items" in this.props.page.config
      && this.props.page.config.items.children);
    let childrenQuery = (!show_children) ? "" :
      `children {
        id
        list_id
        created_at
        updated_at
        archived_at
        cue
        data
        parent {
          id
          list_id
          created_at
          updated_at
          archived_at
          bookmark
          cue
          data
        }
        parent_id
        parent_list_id
        parent_page_id
      }`;

    // Create query
    let query = `query
      GetList($token: String!, $page_id: ID!, $list_id: ID!, $updated_since: Int, $archived_since: Int) {
        getList(token: $token, page_id: $page_id, list_id: $list_id) {
          new_token
          items (updated_since: $updated_since) {
            id
            list_id
            created_at
            updated_at
            archived_at
            cue
            data
            parent {
              id
              list_id
              created_at
              updated_at
              archived_at
              bookmark
              cue
              data
            }
            parent_id
            parent_list_id
            parent_page_id
            ${childrenQuery}
          }
          items_archived (archived_since: $archived_since) {
            id
            list_id
            created_at
            updated_at
            archived_at
            cue
            data
            parent {
              id
              list_id
              created_at
              updated_at
              archived_at
              bookmark
              cue
              data
            }
            parent_id
            parent_list_id
            parent_page_id
            ${childrenQuery}
          }
        }
      }`;

    // Define params
    let params = {
      token: this.props.token,
      page_id: this.props.page_id,
      list_id: list_id,
      updated_since: last_updated,
      archived_since: (last_updated === 0) ? now : last_updated
    };

    // Query API
    API.graphql(graphqlOperation(query, params))
      // Success
      .then((res) => {
        const data = res.data.getList;
        // Update token
        if(data.new_token) this.updateToken(data.new_token);

        // Update items
        for(let i=0; i<data.items.length; i++) {
          // Check if showing items or their children
          if(!show_children) this.updateItem(data.items[i]);
          else {
            // Check for children in item
            if(!("children" in data.items[i])) continue;
            // Loop through children
            for(let j=0; j<data.items[i].children.length; j++) {
              // Update child item in page
              this.updateItem(data.items[i].children[j]);
            }
          }
        }

        // Remove archived items
        for(let i=0; i<data.items_archived.length; i++) {
          // Check if showing items or their children
          if(!show_children) this.updateItem(data.items_archived[i]);
          else {
            // Check for children in item
            if(!("children" in data.items_archived[i])) continue;
            // Loop through children
            for(let j=0; j<data.items_archived[i].children.length; j++) {
              // Update child item in page
              this.updateItem(data.items_archived[i].children[j]);
            }
          }
        }

        // Record update event if no items returned
        if(data.items.length === 0
        && data.items_archived.length === 0
        && this.state.last_updated === 0) {
          this.setState({
            last_updated: now
          });
        }

        // Callback
        if(callback) callback();
      })
      // Error
      .catch((err) => {
        console.log(err);
        this.disconnected(true);
      });
  }

  redirect(path) {
    this.setState({ redirect: path });
  }

  toggleArchive(bool) {
    // Analytics
    Analytics.event((bool) ? "archive_shown" : "archive_hidden", {
      event_category: "app_page",
      page_name: this.props.page.name,
      page_id: this.props.page.id
    });
    // Toggle to opposite of current state unless a boolean is specified
    let show_archive = (typeof bool === "boolean")
      ? bool
      : !this.state.show_archive;
    // Update state
    this.setState({ show_archive: show_archive });
  }

  updateParentItem(parent) {
    // Get items from page
    let items = cloneDeep(this.state.items);

    // Loop through items to find those with this parent
    let changed = false;
    for(let i=0; i<items.length; i++) {
      let item = items[i];

      // Check if item matches this parent
      if("parent" in item
      && item.parent !== null
      && item.parent.id === parent.id) {
        // Track change
        changed = true;

        // Add parent to item
        item.parent = cloneDeep(parent);

        // Archive child if parent was archived
        if(parent.archived_at !== null) item.archived_at = parent.archived_at;

        // Update item in page
        items = mutateItem(items, item)[0];
      }
    }

    // Update items
    if(changed) this.updateItems(items, this.state.last_updated);
  }

  updateItem(item) {
    // Check for newer update timestamp
    let updated_at = (Util.isNumeric(item.updated_at)) ? item.updated_at : 0;
    let archived_at = (Util.isNumeric(item.archived_at)) ? item.archived_at : 0;
    let item_updated = Math.max(updated_at, archived_at);
    let last_updated = this.state.last_updated;
    if(item_updated > last_updated) {
      last_updated = item_updated;
    }
    // Update items in page
    let items = this.state.items;
    let mutatedItems = mutateItem(items, item);
    // Update items if they were mutated (prevent unneccesary updates)
    if(mutatedItems[1]) {
      // Update items
      this.updateItems(mutatedItems[0], last_updated);
      // Invoke actions
      this.updateItemActions(mutatedItems[0], item, mutatedItems[2])
    }
  }

  updateItemActions(items, item, mutation) {
    // Add mutation to item
    item._mutation = mutation;

    // Get item actions from config
    let actions = ("config" in this.props.page
    && "items" in this.props.page.config
    && "actions" in this.props.page.config.items)
      ? this.props.page.config.items.actions
      : {};

    // Prepare data for inserting variables
    let ivdata = {
      token: this.props.token,
      groups: this.props.groups,
      user: this.props.user,
      item: item,
      items: items,
      page: this.props.page,
      openForm: this.openForm.bind(this),
      redirect: this.redirect.bind(this),
      updateItem: this.updateItem.bind(this)
    };

    // Invoke actions after item was mutated
    Object.keys(actions).forEach((key) => {
      Actions.doAction(actions[key], ivdata);
    });
  }

  updateItems(items, last_updated) {
    // Sort items
    let config = ("config" in this.props.page
    && this.props.page.config !== null)
      ? this.props.page.config
      : {};
    let sortedItems = sortItems(items, config);

    // Update items
    this.setState({
      items: sortedItems,
      last_updated: last_updated
    });
  }

  updateToken(new_token) {
    this.setState({ token: new_token });
    this.props.updateToken(new_token);
  }

  render() {
    // Redirect
    if(this.state.redirect !== false) {
      return <Redirect to={this.state.redirect} push={true}/>;
    }

    // Evaluate `if`
    if(!(this.evaluateIf())) {
      return <Status status="link" link="/"/>
    }

    // Redirect to reports if main page is disabled
    if(this.props.page.config.page === false) {
      return <Status status="link" link="/"/>
    }

    // Buttons
    const config = this.props.page.config;
    let buttons = ("buttons" in config) ? config.buttons : {};
    // Add back button
    if(!("back" in buttons)) {
      buttons.back = {
        icon: "arrow-back",
        title: "Back",
        position: "topleft",
        action: {
          function: "link",
          link: "/"
        }
      };
    }

    // Intentional vs. error disconnect
    const statusError = (this.state.status === "disconnected-error");
    const status = (this.state.status === null) ? null :
      this.state.status.replace('disconnected-error', 'disconnected');

    // Status callback
    let statusCallback;
    if(status === "disconnected") {
      statusCallback = this.reconnect.bind(this);
    }

    // Cards
    let cards = (
      <div className="cards">
        <Spacer position="top"/>
        <ArchiveToggle
          config={config}
          toggleArchive={this.toggleArchive.bind(this)}
          showArchive={this.state.show_archive}/>
        <Lists
          showArchive={this.state.show_archive}
          token={this.props.token}
          last_updated={this.state.last_updated}
          updateItem={this.updateItem.bind(this)}
          updateItems={this.updateItems.bind(this)}
          page_id={this.props.page_id}
          groups={this.props.groups}
          user={this.props.user}
          page={this.props.page}
          faux_items={this.state.faux_items}
          items={this.state.items}
          openForm={this.openForm.bind(this)}
          redirect={this.redirect.bind(this)}
          subscribe={this.subscribe.bind(this)}/>
        <ListsArchived
          showArchive={this.state.show_archive}
          token={this.props.token}
          page_id={this.props.page_id}
          page={this.props.page}
          openForm={this.openForm.bind(this)}
          redirect={this.redirect.bind(this)}/>
        <Footer
          token={this.props.token}/>
      </div>
    );

    // Org status
    if("status" in this.props.status
    && this.props.status.status !== "enabled") {
      // Disable buttons other than "back" button
      buttons = { back: buttons.back };
      // Show status instead of cards
      cards = (
        <Status
          status={this.props.status.status}
          description={this.props.status.description}
          token={this.props.token}/>
      );
    }

    // Page content
    return (
      <div
        className="AppPage page">
        <Status
          callback={statusCallback}
          error={statusError}
          status={status}/>
        <InstallPrompt
          token={this.props.token}
          showDownloadModal={this.props.showDownloadModal.bind(this)}/>
        <Updater
          user={this.props.user}
          token={this.props.token}
          updateToken={this.props.updateToken.bind(this)}/>
        <Form
          openForm={this.openForm.bind(this)}
          redirect={this.redirect.bind(this)}
          closeForm={this.closeForm.bind(this)}
          getUsers={this.props.getUsers.bind(this)}
          users={this.props.users}
          form_data={this.state.form_data}/>
        <Buttons
          token={this.props.token}
          openForm={this.openForm.bind(this)}
          redirect={this.redirect.bind(this)}
          buttons={buttons}
          groups={this.props.groups}
          user={this.props.user}
          page={this.props.page}/>
        <Header
          color={(this.props.page.color !== null) ? this.props.page.color : ""}
          icon={(this.props.page.icon !== null) ? this.props.page.icon : "list-box"}
          title={this.props.page.name}/>
        {cards}
      </div>
    )
  }
}

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: []
    };
  }

  componentDidUpdate(prevProps) {
    // Update token in Amplify headers on change (updated token or switch to kiosk mode)
    if(prevProps.token !== this.props.token) configureAmplify();
  }

  getUsers(group_ids) {
    const submit = async () => {
      // Create query
      let query = `query
        GetOrg($token: String!, $group_ids: [ ID ]) {
          getOrg(token: $token) {
            users(group_ids: $group_ids) {
              id
              name
              email
              phone
              employee_id
              group_ids
            }
          }
        }`;
      // Create payload
      let payload = {
        token: this.props.token,
        group_ids: (group_ids) ? group_ids : []
      };
      // Submit to API
      return await API.graphql(graphqlOperation(query, payload));
    }
    submit().then(
      (res)=>{
        let users = res.data.getOrg.users;
        if(users.length === 0) users.push({});  // Push empty user to stop loading
        this.setState({ users: users });
      },
      (err)=>{ console.log(err) }
    );
  }

  render() {
    // Specify `page` fields
    let pageFields = `
      id
      name
      icon
      color
      description
      config
      lists {
        id
      }
      list_ids
      zones {
        list_id
        required
        name
      }`;

    // Create payload
    let payload = {
      token: this.props.token,
      page_id: this.props.page_id
    };

    // Create query
    let userIdVar = "", userQuery = "";
    if(isObject(this.props.user)
    && this.props.user.id
    && !("name" in this.props.user)) {
      // Add existing user to payload
      payload.user_id = this.props.user.id;
      // Add user to query
      userIdVar = `$user_id: ID!,`
      userQuery = `users(user_id: $user_id) {
        name,
        email,
        phone,
        employee_id
      }`
    }
    // Get org
    let query = `query
      GetOrg($token: String!, `+userIdVar+` $page_id: ID!) {
        getOrg(token: $token) {
          new_token
          status
          groups {
            id
            name
            admin
          }
          `+userQuery+`
          pages(page_id: $page_id) {
            `+pageFields+`
          }
        }
      }`;

    // Get data from GraphQL
    return (
      <Connect
        query={graphqlOperation(query, payload)}>
        {({ data, errors }) => {
          // Handle errors
          if(errors.length > 0) {
            if(errors[0].message.indexOf("token") >= 0) {
              return <Status status="logout"/>;
            } else return <Status status="error"/>;
          }
          if(data === null) return <Status status="link" link="/orgs"/>;

          // Copy page config
          let page = {};
          if(data && "getOrg" in data) {
            // Set page config
            if(data.getOrg.pages.length > 0) {
              page = (isObject(data.getOrg.pages[0]))
                ? cloneDeep(data.getOrg.pages[0]) : {};
            }
            // Redirect to orgs if invalid page or page from another org
            else return <Status status="link" link="/orgs"/>;
          } else if(!this.props.preloaded) {
            // Indicate loading
            return <Status status="loading"/>;
          }

          // Handle loading
          if(Object.keys(data).length === 0 && this.props.preloaded) {
            // Pass pre-loaded content from HomePage while waiting for page to load
            page = (this.props.preloaded) ? this.props.preloaded : {};
            // Indicate content is preloaded, not the actual page
            page.preloaded = true;
          }

          // Status
          let status = ("getOrg" in data)
            ? JSON.parse(data.getOrg.status) : {};

          // Parse config
          page.config = (!("config" in page) || page.config === null)
            ? {}
            : (typeof page.config !== "object")
              ? JSON.parse(page.config)
              : page.config;

          // Insert config variables
          page.config = Util.insertConfigVariables(page.config);

          // Update page title
          if(page.name) this.props.setTitle(page.name);

          // User details
          let user = this.props.user;
          if(data
          && "getOrg" in data
          && "users" in data.getOrg
          && data.getOrg.users.length > 0) {
            user.name = data.getOrg.users[0].name;
            user.email = data.getOrg.users[0].email;
            user.phone = data.getOrg.users[0].phone;
            user.employee_id = data.getOrg.users[0].employee_id;
          }

          // Groups
          let groups = [];
          if(data && "getOrg" in data && data.getOrg.groups.length > 0) {
            groups = data.getOrg.groups;
          }

          // Show data
          return (
            <PageContent
              showDownloadModal={this.props.showDownloadModal.bind(this)}
              loaded={("getOrg" in data)}
              token={this.props.token}
              new_token={("new_token" in page) ? page.new_token : null}
              updateToken={this.props.updateToken.bind(this)}
              status={status}
              groups={groups}
              user={user}
              users={this.state.users}
              getUsers={this.getUsers.bind(this)}
              page_id={this.props.page_id}
              page={page}/>
          );
        }}
      </Connect>
    );
  }
}

export default Page;
