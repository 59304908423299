import React, { Component } from 'react';
import Icon from "../Icon/Icon";
import * as Actions from "../Actions/Actions";
import * as Util from "../Utilities/Utilities";
import "./Form.css";
import Analytics from '../analytics';
import Map from "../Map/Map";
import Workflow from "../Workflow/Workflow";
import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isPlainObject';

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      checked: this.is_checked(this.props.config.checked),
      loading: false,
      options: []
    };
    this.updateInputValue = this.props.updateInputValue.bind(this);
  }

  componentDidMount() {
    // Prepare utility data
    const ivdata = {
      response: this.props.response,
      item: this.props.item,
      items: this.props.items,
      zone: this.props.zone,
      form: this.props.form,
      page: this.props.page,
      groups: this.props.groups,
      user: this.props.user
    };
    // Get config
    const config = this.props.config;

    // Determine value
    let value = (!("value" in config) || config.value === null) ? "" :
      Util.insertVariables(config.value, ivdata, false, false);
    // Prevent null values
    value = (value === null || value === "null") ? "" : value;
    // Update value in state
    this.setState({ value: value });
    // Checkbox workaround
    if(this.props.config.type === "checkbox") {
      // Set value in form data as blank unless default is checked
      let checked = this.props.config.checked;
      if(!this.is_checked(checked)) value = "";
    }
    // Update value in parent's state
    this.updateInputValue(this.props.id, value);

    // Determine options
    this.parseOptions(value);
  }

  componentDidUpdate(prevProps) {
    // Update state if value changed
    if(prevProps.value !== this.props.value) {
      if(this.props.config.type !== "checkbox") {
        let value = this.props.value;
        this.setState({
          // No `null` value
          value: (value === null) ? "" : value
        });
      }
    }

    // Update options if `users` added
    if(JSON.stringify(prevProps.users)
    !== JSON.stringify(this.props.users)) {
      this.parseOptions();
    }
  }

  is_checked(checked) {
    // Handle strings
    if(typeof checked === "string") {
      // Prepare utility data
      const ivdata = {
        response: this.props.response,
        item: this.props.item,
        items: this.props.items,
        zone: this.props.zone,
        form: this.props.form,
        page: this.props.page,
        groups: this.props.groups,
        user: this.props.user
      };
      // Get config
      const config = this.props.config;

      // Insert variables
      checked = Util.insertVariables(
        config.checked, ivdata, false, false);
    }

    // Return boolean
    return (checked && checked !== "false" && checked !== "null")
      ? true : false;
  }

  handleChange(event) {
    // Get value
    let value = event.target.value;
    // Update value in state if not a checkbox
    if(event.target.type !== "checkbox") {
      this.setState({ value: value });
    } else {
      // Update checkbox in state
      let checked = event.target.checked;
      this.setState({ checked: checked });
      // Checkbox workaround
      if(checked === false) value = "";
    }
    // Get text & meta
    let text = value;
    let meta
    if(typeof event.target.selectedIndex !== "undefined") {
      text = event.target[event.target.selectedIndex].text;
      meta = event.target[event.target.selectedIndex].meta;
    }
    // Update value in parent
    this.updateInputValue(event.target.name, value, text, meta);
  }

  parseOptions(stateValue) {
    // Get input's value
    if(typeof stateValue === "undefined") stateValue = this.state.value;
    // Prepare utility data
    const ivdata = {
      response: this.props.response,
      item: this.props.item,
      items: this.props.items,
      zone: this.props.zone,
      form: this.props.form,
      page: this.props.page,
      groups: this.props.groups,
      user: this.props.user,
      users: this.props.users
    };
    // Get config
    const config = this.props.config;
    // Parse options
    if("options" in config) {
      // Determine if users need to be queried
      let match = JSON.stringify(config.options)
        .match(/\$users\.(?:id|name)\((.*?)\)/);
      if(this.props.users.length === 0 && match !== null) {
        // Get users from API (regardless of group_id)
        this.props.getUsers();
        // Indicate loading
        this.setState({ loading: true });
        // Stop function
        return;
      } else {
        // Stop loading
        this.setState({ loading: false });
      }

      // Fallback if options are empty
      let options = ("options" in config && config.options !== null)
        ? config.options : [];
      // If options is a string
      if(typeof options === "string") {
        options = Util.insertVariables(options, ivdata, false, true);
        options = JSON.parse(options);
      }

      // If options is an array
      if(Array.isArray(options)) {
        let parsed_options = [];
        for(let i=0; i<options.length; i++) {
          // Parse this option (either string or array of additional options)
          let option = Util.insertVariables(options[i], ivdata, false, true);
          try { option = JSON.parse(option) } catch(e) {}
          // If this option is an array, add to array of all options
          if(Array.isArray(option)) parsed_options = parsed_options.concat(option);
          else parsed_options.push(option);
        }
        // Overwrite options with parsed options
        options = parsed_options;
      }

      // If options is an object
      if(typeof options === "object" && !Array.isArray(options)) {
        // Get values and texts
        let vals = ("values" in options) ? options.values : "";
        let texts = ("texts" in options) ? options.texts : "";
        let metas = ("metas" in options) ? options.metas : "";

        // Process strings
        if(typeof vals === "string" && vals !== "_index") {
          vals = Util.insertVariables(vals, ivdata, false, true);
          vals = (vals !== "") ? JSON.parse(vals) : [];
        }
        if(typeof texts === "string") {
          texts = Util.insertVariables(texts, ivdata, false, true);
          texts = (texts !== "") ? JSON.parse(texts) : [];
        }
        if(typeof metas === "string") {
          metas = Util.insertVariables(metas, ivdata, false, true);
          metas = (metas !== "") ? JSON.parse(metas) : [];
        }

        // Process arrays
        if(Array.isArray(vals)) {
          let parsed_vals = [];
          for(let i=0; i<vals.length; i++) {
            // Parse val
            let val = Util.insertVariables(vals[i], ivdata, false, true);
            try { val = JSON.parse(val) } catch(e) {}
            // Add to parsed_vals
            if(Array.isArray(val)) parsed_vals = parsed_vals.concat(val);
            else parsed_vals.push(val);
          }
          vals = parsed_vals;
        }
        if(Array.isArray(texts)) {
          let parsed_texts = [];
          for(let i=0; i<texts.length; i++) {
            // Parse text
            let text = Util.insertVariables(texts[i], ivdata, false, true);
            try { text = JSON.parse(text) } catch(e) {}
            // Add to parsed_texts
            if(Array.isArray(text)) parsed_texts = parsed_texts.concat(text);
            else parsed_texts.push(text);
          }
          texts = parsed_texts;
        }
        if(Array.isArray(metas)) {
          let parsed_metas = [];
          for(let i=0; i<metas.length; i++) {
            // Parse meta
            let meta = Util.insertVariables(metas[i], ivdata, false, true);
            try { meta = JSON.parse(meta) } catch(e) {}
            // Add to parsed_metas
            if(Array.isArray(meta)) parsed_metas = parsed_metas.concat(meta);
            else parsed_metas.push(meta);
          }
          metas = parsed_metas;
        }

        // Handle empty `vals`, `texts`, and `metas`
        if(vals === "") vals = [];
        if(texts === "") texts = [];
        if(metas === "") metas = [];

        // Use index as values
        if(vals === "_index") {
          vals = Array.from(Array(texts.length).keys());
        }

        // Create array of options
        options = [];
        for(let i=0; i<Math.max(vals.length, texts.length); i++) {
          let option = {};
          // Skip empty options
          if(vals[i] === null && texts[i] === null) continue;
          // Add value
          if(vals.length > i) option.value = vals[i];
          else if(texts.length > i) option.value = texts[i];
          // Add text
          if(texts.length > i) option.text = texts[i];
          else if(vals.length > i) option.text = vals[i];
          // Add meta
          if(metas.length > i) option.meta = metas[i];
          else if(vals.length > i) option.meta = vals[i];
          // Add option to aray
          options.push(option);
        }

      }

      // Loop through options
      for(let i=0; i<options.length; i++) {
        let val, text, meta, disabled, ifs;
        // Determine value and text
        if(options[i] === null) {
          val = "";
          text = val;
        } else {
          if(typeof options[i] === "string") {
            val = options[i];
            text = val;
          }
          if(isObject(options[i])) {
            val = ("value" in options[i])
              ? options[i].value : options[i].text;
            text = ("text" in options[i])
              ? options[i].text : options[i].value;
            if("meta" in options[i]) meta = options[i].meta;
            if("disabled" in options[i]) disabled = options[i].disabled;
            if("if" in options[i]) ifs = options[i].if;
          }
        }

        // Process options
        options[i] = {
          value: Util.insertVariables(val, ivdata, false, false),
          text: Util.insertVariables(text, ivdata, false, false),
          meta: Util.insertVariables(meta, ivdata, false, false),
          disabled: Util.insertVariables(disabled, ivdata, false, false),
          if: Util.insertVariables(ifs, ivdata, false, false)
        }
        // Update input value's text & meta
        if(val.toString() === stateValue.toString()) {
          this.updateInputValue(this.props.id, val, text, meta);
        }
      }

      // Update options
      this.setState({ options: options });
    }
  }

  render() {
    // Get config
    const config = this.props.config;

    // Check if input should be created
    if("if" in config) {
      // Do not create input if `config.if` is false
      if(!Util.evalIf(config.if, {
        token: this.props.token,
        groups: this.props.groups,
        user: this.props.user,
        item: this.props.item,
        items: this.props.items,
        form: this.props.form,
        page: this.props.page
      })) return false;
    }

    // Label
    const label = (!("label" in config) || config.label === null) ? "" : (
      <span
        key="label"
        title={(config.required) ? "This input is required" : ""}
        className={"label" + ((config.required) ? " required" : "")}>
        {config.label}
      </span>
    );

    // Placeholder
    const placeholder = ("placeholder" in config)
      ? config.placeholder : "";

    // Input title
    const input_title = ("title" in config)
      ? config.title : "";

    // Input validation pattern
    const pattern = ("pattern" in config)
      ? config.pattern : ".*";

    // Input
    const type = ("type" in config && config.type !== null)
      ? config.type : "input";
    let input = "";
    switch(type) {
      case "hidden":
        input = (
          <input
            key="input"
            type="hidden"
            name={this.props.id}
            value={this.state.value}
            required={(config.required)}/>
        );
        break;

      case "select":
        let options = this.state.options;
        // Create options
        let values = [];
        // Create blank option at start
        if(!this.state.loading) {
          options = [{
            value: "",
            text: "",
            disabled: false
          }].concat(options);
        } else {
          // Indicate loading
          options = [{
            value: "",
            text: "Loading...",
            disabled: true,
            loading: true
          }];
        }
        // Loop through options
        for(let i=0; i<options.length; i++) {
          // Check if option should be created
          if("if" in options[i]) {
            if(!Util.evalIf(options[i].if, {
              token: this.props.token,
              groups: this.props.groups,
              user: this.props.user,
              item: this.props.item,
              items: this.props.items,
              form: this.props.form,
              page: this.props.page
            })) continue;
          }

          // Add option to values
          values.push(
            <option
              key={"option"+[i]}
              disabled={(options[i].disabled)}
              meta={(options[i].meta)}
              value={options[i].value}>
              {options[i].text}
            </option>
          );
        }
        // Create select input
        input = (
          <div
            key={this.props.id}
            className={"select" + ((config.disabled) ? " disabled" : "")}>
            <select
              style={{ color: (this.state.loading) ? "#aaa" : "" }}
              title={input_title}
              name={this.props.id}
              onChange={this.handleChange.bind(this)}
              value={this.state.value}
              disabled={(config.disabled) ? "disabled" : ""}
              required={(config.required)}>
              {values}
            </select>
          </div>
        );
        // Break switch
        break;

      case "checkbox":
        input = (
          <input
            key="input"
            type="checkbox"
            title={input_title}
            name={this.props.id}
            value={this.state.value}
            onChange={this.handleChange.bind(this)}
            disabled={(config.disabled) ? "disabled" : ""}
            checked={this.state.checked}/>
        );
        input = (
          <div
            key="input"
            className="checkbox">
            {input}
            <label
              key="label"
              htmlFor={this.props.id}/>
          </div>
        );
        break;

      case "email":
        input = (
          <input
            key="input"
            type="email"
            pattern={pattern}
            title={input_title}
            name={this.props.id}
            placeholder={placeholder}
            value={this.state.value}
            onKeyUp={this.handleChange.bind(this)}
            onChange={this.handleChange.bind(this)}
            disabled={(config.disabled) ? "disabled" : ""}
            required={(config.required)}/>
        );
        break;

      default:
        // Create text input
        input = (
          <input
            key="input"
            type="text"
            pattern={pattern}
            title={input_title}
            name={this.props.id}
            placeholder={placeholder}
            value={this.state.value}
            onKeyUp={this.handleChange.bind(this)}
            onChange={this.handleChange.bind(this)}
            disabled={(config.disabled) ? "disabled" : ""}
            required={(config.required)}/>
        );
    }

    // Checkbox style
    if(type === "checkbox") return (
      <tr className="checkbox-row">
        <th></th>
        <th>{input}</th>
        <td>{label}</td>
      </tr>
    );

    // Return input
    return (
      <tr>
        <th colSpan="1">{label}</th>
        <td colSpan="2">{input}</td>
      </tr>
    );
  }
}

class Inputs extends Component {
  render() {
    // Handle no inputs
    if(!("inputs" in this.props)
    || typeof this.props.inputs === "undefined"
    || this.props.inputs.length === 0) {
      return "";
    }

    // Generate inputs
    let inputs = [];
    let visibleInputs = 0;
    // Add hidden row to maintain column widths
    inputs.push(
      <tr key="hidden" className="hidden_row">
        <th/><th/><td/>
      </tr>
    );
    // Get inputs from props
    Object.keys(this.props.inputs)
    // Sort conditions
    .sort((a, b) => {
      let sort = 0;
      // Get conditions
      let orderA = ("order" in this.props.inputs[a])
        ? this.props.inputs[a].order : null;
      let orderB = ("order" in this.props.inputs[b])
        ? this.props.inputs[b].order : null;

      // Sort by order
      if(orderA !== null && orderB !== null) sort = orderA - orderB;
      else if(orderA === null && orderB === null) sort = 0;
        else if(orderA !== null && orderB === null) sort = -1;
          else if(orderA === null && orderB !== null) sort = 1;

      // Sort by ID, if needed
      if(sort === 0) sort = a.localeCompare(b);

      // Return result
      return sort;
    })
    // Loop through conditions
    .forEach((key) => {
      let inputConfig = this.props.inputs[key];
      // Count visible inputs
      if(!("type" in inputConfig) || inputConfig.type !== "hidden") {
        visibleInputs++;
      }
      // Value
      const value = (key in this.props.inputValues)
        ? (typeof this.props.inputValues[key].value !== "undefined")
          ? this.props.inputValues[key].value : null : null;
      // Add input
      inputs.push(
        <Input
          key={key}
          response={this.props.response}
          item={this.props.item}
          items={this.props.items}
          zone={this.props.zone}
          form={this.props.form}
          page={this.props.page}
          groups={this.props.groups}
          user={this.props.user}
          users={this.props.users}
          getUsers={this.props.getUsers.bind(this)}
          updateInputValue={this.props.updateInputValue.bind(this)}
          value={value}
          id={key}
          config={inputConfig}/>
      );
    });

    // Return inputs if any are visible
    let inputsClass = "inputs" + ((visibleInputs === 0)
      ? " hidden" : "");
    return (
      <table className={inputsClass}>
        <tbody>
          {inputs}
        </tbody>
      </table>
    );
  }
}

class FormButtons extends Component {
  render() {
    // Default buttons
    let buttons = {
      "close": {
        "label": "Close",
        "type": "close",
        "order": 1
      },
      "submit": {
        "label": "Submit",
        "type": "submit",
        "order": 2
      }
    };

    // Inherit buttons
    if(this.props.buttons) buttons = this.props.buttons;

    // Create button elements
    let buttonElements = [];
    Object.keys(buttons)
    .sort(function(a, b) {
      if("order" in buttons[a]) {
        if("order" in buttons[b]) return buttons[a].order - buttons[b].order;
        else return -1;
      } else if("order" in buttons[b]) return 1;
      return a.localeCompare(b);
    })
    .forEach(function(key, i) {
      let b = buttons[key];
      let classes = [];
      // Type
      let type = (b.type) ? b.type : "submit";
      if(type === "close") {
        type = "reset";
        classes.push('close');
      }
      // Element
      buttonElements.push(
        <input
          key={i}
          className={classes.join(" ")}
          value={(b.label) ? b.label : key}
          type={type}/>
      );
    });

    return (
      <div className="buttons">
        {buttonElements}
      </div>
    )
  }
}

class Barcode extends Component {
  constructor(props) {
    super(props);
    this.barcodeListener = this.barcodeListener.bind(this);
    this.state = {
      scanning: false,
      error: false,
      hiddenValue: ""
    };
  }

  componentWillUnmount() {
    // Remove barcode listener
    document.removeEventListener("onDataInjection", this.barcodeListener);
  }

  show_barcode(config) {
    // Check for barcode
    if(config === false) return false;

    // Prepare data for inserting variables
    let ivdata = {
      response: this.props.response,
      token: this.props.token,
      item: this.props.item,
      items: this.props.items,
      zone: this.props.zone,
      groups: this.props.groups,
      page: this.props.page
    };

    // Check for `if` in config
    return Util.evalIf(config.if, ivdata);
  }

  startScan() {
    // Clear barcode scan error
    if(this.state.error) this.setState({ error: false });

    // Check if in native app
    if(window.isNative) {
      // Check if barcode scanning is supported
      if(Util.insertVariables("$app.minVersion(1.1.0)") === "false") {
        return alert("Please update your app to use this feature");
      }
      // Open barcode scanner in native app
      window.postWebappData({ open_barcode_scanner: true });
      // Stop any existing listeners
      document.removeEventListener("onDataInjection", this.barcodeListener);
      // Listen for scan result
      document.addEventListener("onDataInjection", this.barcodeListener);
    }

    // If webapp, wait for external scanner result
    else {
      // Finish scan if already in progress
      if(this.state.scanning) this.finishScan();
      // Indicate scan has started
      this.setState({ scanning: true });
      // Focus on hidden input
      this.hiddenInput.focus();
    }
  }

  barcodeListener(event) {
    if("barcode" in event.detail) {
      // Process scan
      if("data" in event.detail.barcode) {
        this.barcodeCallback(event.detail.barcode.data);
      }
      // Stop listening
      document.removeEventListener("onDataInjection", this.barcodeListener);
    }
  }

  finishScan() {
    // Indicate scan has finished
    this.setState({ scanning: false });
    // Focus on hidden input
    this.hiddenInput.blur();
  }

  handleBarcode(event) {
    // Get value from scan
    const value = event.target.value;
    // Indicate scan has stopped
    this.finishScan();
    // Process scan
    this.barcodeCallback(value);
  }

  barcodeCallback(value) {
    const barcode_config = this.props.config;

    // Check if config uses legacy method
    if(!barcode_config.callback) return this.processScan(value);

    // Check barcode pattern
    if("pattern" in barcode_config && barcode_config.pattern) {
      // Check if `pattern` is a regular expression string
      const patternMatch = barcode_config.pattern.match(/\/(.+)\/(\w*)/);
      if(patternMatch !== null) {
        // Convert pattern to regular expression
        const pattern = new RegExp(patternMatch[1], patternMatch[2]);
        // Check barcode value against pattern
        if(!pattern.test(value)) {
          this.setState({ error: true });
          return;
        }
      }
    }

    // Get props to pass to action (item, page, etc.)
    let props = this.props.form_data;
    // Add form data to props
    props.form = this.props.inputValues;
    // Allow callback to open another form
    props.openForm = ("openForm" in props)
      ? props.openForm.bind(this)
      : () => {};
    // Allow callback to update this form
    props.updateInputValue = this.props.updateInputValue.bind(this);
    props.updateMapConfig = this.props.updateMapConfig.bind(this);
    // Add barcode's value to response
    props.response = value;
    // Perform callback action
    Actions.doAction(barcode_config.callback, props);
  }

  processScan(value) {  // Legacy method, all accounts should switch to newer callback-based method
    const config = this.props.config;
    // Get regular expression from config
    let regexStr = ("regex" in config) ? config.regex : "";
    // Convert text to RegEx
    const regexMatch = regexStr.match(/\/(.+)\/(\w*)/);
    // Exit if regexMatch is invalid
    if(regexMatch === null) {
      this.setState({ error: true });
      return;
    }
    // Convert regexMatch to a reegular expression
    const regex = new RegExp(regexMatch[1], regexMatch[2]);

    // Match regular expression to scan value
    const match = value.match(regex);
    // Exit if regex does not match scanned value
    if(match === null) {
      this.setState({ error: true });
      return;
    }

    // Loop through inputs and update each with a `barcode_index`
    const inputs = this.props.inputs;
    Object.keys(inputs).forEach((key) => {
      // Check for barcode index in each input
      if("barcode_index" in inputs[key]) {
        // Check if barcode index exists in match
        if(match.length > inputs[key].barcode_index) {
          // Remove whitespace from value
          let barcode_value = match[inputs[key].barcode_index].trim();
          // Update input's value
          this.props.updateInputValue(key, barcode_value);
        }
      }
    });
  }

  render() {
    const config = this.props.config;

    // Check if barcode should be shown
    if(this.show_barcode(config) === false) return "";

    // Barcode type
    const scanType = ("type" in config &&
      (config.type === "bar" || config.type === "qr"))
        ? config.type : "bar";

    // Scan status
    let scanClass = "barcode";
    let scanStatus = ("title" in config && config.title !== null)
      ? config.title
      : (scanType === "qr") ? "Scan QR Code" : "Scan Barcode";
    if(this.state.scanning) {
      scanClass = "barcode scanning";
      scanStatus = "Scanning...";
    }
    if(this.state.error) {
      scanClass = "barcode error";
      scanStatus = (scanType === "qr") ? "QR Code Error" : "Barcode Error";
    }

    // Return barcode
    return <div className="barcode_container">
      <div
        className={scanClass}
        onClick={this.startScan.bind(this)}>
        <Icon
          name={(scanType === "qr") ? "qr-code" : "barcode-real"}/>
        <div className="scanStatus">
          {scanStatus}
        </div>
        <div className="hideInput">
          <input
            onChange={this.handleBarcode.bind(this)}
            onBlur={this.finishScan.bind(this)}
            value={this.state.hiddenValue}
            ref={(input) => { this.hiddenInput = input; }}
            type="text"/>
        </div>
      </div>
    </div>;
  }
}

class FormContent extends Component {
  constructor(props) {
    super(props);
    this.updateInset = this.updateInset.bind(this);
    this.state = {
      map_config: (this.props.form_data
      && "config" in this.props.form_data
      && this.props.form_data.config.map)
        ? this.props.form_data.config.map : {},
      inputs: {},
      top_inset: (window.injectedData.safe_area_insets)
        ? window.injectedData.safe_area_insets.top : false
    };
  }

  componentDidMount() {
    // Update inset on native injection
    document.addEventListener("onDataInjection", this.updateInset);
    // Analytics
    this.formAnalytics(true);
    // Perform action after form is opened
    this.on_open();
  }

  componentDidUpdate(prevProps) {
    if(JSON.stringify(prevProps.form_data) !==
    JSON.stringify(this.props.form_data)) {
      // Analytics
      this.formAnalytics(true);
    }
  }

  formAnalytics(opened) {
    // Analytics on form open or close
    Analytics.event((opened) ? "form_opened" : "form_closed", {
      event_category: "form",
      event_label: (this.props.form_data.config.title)
        ? this.props.form_data.config.title : null
    });
  }

  componentWillUnmount() {
    // Remove event lsiteners before unmounting component
    document.removeEventListener("onDataInjection", this.updateInset);
  }

  closeForm(event, interaction) {
    // Check if appropriate element was clicked
    if(event.target.className === "coverall"
    || event.target.className === "close") {
      // Record user-initiated form close in analytics
      this.formAnalytics(false);
      // Close form
      this.props.closeForm();
    }
  }

  on_open() {
    // Get form config
    const config = this.props.form_data.config;

    // Check if there is an `on_open` action in form config
    if(!("on_open" in config) || config.on_open === null) return;

    // Get props to pass to action (item, page, etc.)
    let props = this.props.form_data;
    // Add form data to props
    props.form = this.state.inputs;
    // Allow callback to open another form
    props.openForm = ("openForm" in props)
      ? props.openForm.bind(this)
      : () => {};
    // Allow callback to update this form
    props.updateInputValue = this.updateInputValue.bind(this);
    props.updateMapConfig = this.updateMapConfig.bind(this);
    // Allow callback to redirect
    props.redirect = ("redirect" in props)
      ? props.redirect.bind(this)
      : () => { return false };
    // Allow callback to update token
    props.updateToken = ("updateToken" in this.props)
      ? this.props.updateToken.bind(this)
      : () => {};

    // Perform callback action
    Actions.doAction(config.on_open, props);
  }

  updateInputValue(name, value, text, meta) {
    // Get existing input values
    let inputs = this.state.inputs;

    // Remove trailing spaces from value
    if(typeof value === "string") value = value.trim();

    // Add input value to object
    inputs[name] = {
      value: value,
      text: (typeof text !== "undefined") ? text : value,
      meta: meta
    };

    // Update state
    this.setState({ inputs: inputs });
  }

  updateInset() {
    if(!("safe_area_insets" in window.injectedData)) return;
    this.setState({ top_inset: window.injectedData.safe_area_insets.top });
  }

  updateMapConfig(param, value) {
    // Get existing map configuration
    let map_config = (isObject(this.state.map_config))
      ? cloneDeep(this.state.map_config) : {};
    // Update param in config
    map_config[param] = value;
    // Update state
    this.setState({ map_config: map_config });
  }

  handleSubmit(event) {
    // Prevent uncontrolled submit
    event.preventDefault();
    // Get form config
    const config = this.props.form_data.config;
    // Get props to pass to action (item, page, etc.)
    let props = this.props.form_data;
    // Add form data to props
    props.form = this.state.inputs;
    // Allow callback to open another form
    props.openForm = ("openForm" in props)
      ? props.openForm.bind(this)
      : () => {};
    // Allow callback to update this form
    props.updateInputValue = this.updateInputValue.bind(this);
    props.updateMapConfig = this.updateMapConfig.bind(this);
    // Allow callback to redirect
    props.redirect = ("redirect" in props)
      ? props.redirect.bind(this)
      : () => { return false };
    // Allow callback to update token
    props.updateToken = ("updateToken" in this.props)
      ? this.props.updateToken.bind(this)
      : () => {};
    // Close form
    this.props.closeForm();
    // Perform callback action
    if("callback" in config && config.callback !== null) {
      Actions.doAction(config.callback, props);
    }
  }

  render() {
    // Get config from form_data
    const config = this.props.form_data.config;

    // Define data for inserting variables
    let ivdata = {
      response: this.props.form_data.response,
      user: this.props.form_data.user,
      item: this.props.form_data.item,
      items: this.props.form_data.items,
      zone: this.props.form_data.zone,
      form: this.props.form_data.form,
      page: this.props.form_data.page,
      groups: this.props.form_data.groups
    };

    // Set style
    const style = ("style" in config) ? Util.camelCase(config.style) : {};

    // Header height adjustment for native app status bar
    let headerStyle = {};
    headerStyle.paddingTop = (this.state.top_inset)
      ? this.state.top_inset+"px"
      : "9px";

    // Form title
    const title = (
      <h1 style={headerStyle}>
        <span
          onClick={(e) => this.closeForm(e, true)}
          className="close">
          Close
        </span>
        {(!("title" in config) || config.title === null) ? "" : config.title}
      </h1>
    );

    // Map coordinates
    let latitude = null,
      longitude = null,
      expiredLocation = null,
      timestamp = null,
      zoom = null,
      accuracy = null,
      mapStyle = null,
      mapDescription = null,
      showMap = false;
    if("latitude" in this.state.map_config
    && "longitude" in this.state.map_config) {
      latitude = ("latitude" in this.state.map_config)
        ? Util.insertVariables(this.state.map_config.latitude, ivdata, true, false)
        : null;
      longitude = ("longitude" in this.state.map_config)
        ? Util.insertVariables(this.state.map_config.longitude, ivdata, true, false)
        : null;
      timestamp = ("timestamp" in this.state.map_config)
        ? Util.insertVariables(this.state.map_config.timestamp, ivdata, true, false)
        : null;
      expiredLocation = ("expired" in this.state.map_config)
        ? Util.evaluate(  // Evaluate to apply math, e.g. `timestamp < now`
          Util.insertVariables(this.state.map_config.expired, ivdata, true, false)
        ) : null;
      zoom = ("zoom" in this.state.map_config)
        ? Util.insertVariables(this.state.map_config.zoom, ivdata, true, false)
        : null;
      accuracy = ("accuracy" in this.state.map_config)
        ? Util.insertVariables(this.state.map_config.accuracy, ivdata, true, false)
        : null;
      mapStyle = ("mapStyle" in this.state.map_config)
        ? Util.insertVariables(this.state.map_config.mapStyle, ivdata, true, false)
        : null;
      mapDescription = ("description" in this.state.map_config)
        ? Util.insertVariables(this.state.map_config.description, ivdata, true, false)
        : null;
      showMap = true;
    }

    // Map loading
    let mapLoading = false;
    if(latitude === "loading" || longitude === "loading"
    || ("loading" in this.state.map_config
    && Util.evalIf(this.state.map_config.loading, ivdata))) {
      mapLoading = true;
      showMap = true;
    }

    // Hide map if `if` resolves to false
    if("if" in this.state.map_config
    && !Util.evalIf(this.state.map_config.if, ivdata)) showMap = false;

    // Workflow
    let workflowConfig = {};
    if("workflow" in config && config.workflow !== null) {
      workflowConfig = config.workflow;
      let item = this.props.form_data.item;
      // item_id
      workflowConfig.item_id = ("item_id" in config.workflow && config.workflow.item_id !== null)
        ? workflowConfig.item_id = Util.insertVariables(config.workflow.item_id, ivdata, true, false)
        : ("parent_id" in item && item.parent_id !== null)
          ? item.parent_id
          : ("id" in item && item.id !== null)
            ? item.id
            : null;
      // archived
      workflowConfig.archived = ("archived" in config.workflow && config.workflow.archived !== null)
      ? workflowConfig.archived = Util.insertVariables(config.workflow.archived, ivdata, true, false)
      : ("archived_at" in item && item.archived_at !== null)
        ? true
        : false;
      // list_id
      workflowConfig.list_id = ("list_id" in config.workflow && config.workflow.list_id !== null)
      ? workflowConfig.list_id = Util.insertVariables(config.workflow.list_id, ivdata, true, false)
      : ("parent_list_id" in item && item.parent_list_id !== null)
        ? item.parent_list_id
        : ("list_id" in item && item.list_id !== null)
          ? item.list_id
          : null;
      // page_id
      workflowConfig.page_id = ("page_id" in config.workflow && config.workflow.page_id !== null)
      ? workflowConfig.page_id = Util.insertVariables(config.workflow.page_id, ivdata, true, false)
      : ("parent_page_id" in item && item.parent_page_id !== null)
        ? item.parent_page_id
        : ("page_id" in item && item.page_id !== null)
          ? item.page_id
          : null;
    }

    // Form message
    let message = "";
    if(config.message) {
      // Message text
      let msgText = Util.insertVariables(config.message, ivdata, true, false);
      // Message element
      message = (
        <div
          className="message"
          dangerouslySetInnerHTML={{ __html: msgText }}>
        </div>
      );
    }

    // Return form
    return (
      <div
        onClick={(e) => this.closeForm(e, true)}
        id="modal_container"
        className="coverall">
        <form
          onSubmit={this.handleSubmit.bind(this)}
          style={style}
          autoComplete="off"
          className="modal">
          <div className="modal_content">
            {title}
            <Map
              show={showMap}
              loading={mapLoading}
              description={mapDescription}
              mapStyle={mapStyle}
              accuracy={accuracy}
              latitude={latitude}
              longitude={longitude}
              timestamp={timestamp}
              expired={expiredLocation}
              zoom={zoom}/>
            {message}
            <Barcode
              form_data={this.props.form_data}
              response={this.props.form_data.response}
              token={this.props.form_data.token}
              item={this.props.form_data.item}
              items={this.props.form_data.items}
              zone={this.props.form_data.zone}
              page={this.props.form_data.page}
              groups={this.props.form_data.groups}
              inputValues={this.state.inputs}
              inputs={config.inputs}
              updateInputValue={this.updateInputValue.bind(this)}
              updateMapConfig={this.updateMapConfig.bind(this)}
              config={("barcode" in config) ? config.barcode : false}/>
            <Workflow
              form_data={this.props.form_data}
              token={this.props.form_data.token}
              config={workflowConfig}/>
            <Inputs
              response={this.props.form_data.response}
              item={this.props.form_data.item}
              items={this.props.form_data.items}
              zone={this.props.form_data.zone}
              form={this.props.form_data.form}
              page={this.props.form_data.page}
              groups={this.props.form_data.groups}
              user={this.props.form_data.user}
              users={this.props.users}
              getUsers={("getUsers" in this.props) ? this.props.getUsers.bind(this) : ()=>{}}
              updateInputValue={this.updateInputValue.bind(this)}
              inputValues={this.state.inputs}
              inputs={config.inputs}/>
            <FormButtons
              buttons={config.buttons}/>
          </div>
        </form>
      </div>
    );
  }
}

class Form extends Component {
  render() {
    // Show form if `config` is in `form_data` (unmount on form close)
    return (!("config" in this.props.form_data)) ? "" :
      <FormContent
        key={this.props.form_data.config.title}
        {...this.props}/>;
  }
}

export default Form;
